import React, { useRef, useEffect } from 'react';
import { defaultValidation } from '../../../../utils/formValidation/FormValidation.js';
import './Radio.css';
import Warning from '../../../atoms/icons/general/warning/Warning';
import { toggleCollapseElement } from '../../../../utils/Utils.js';

// direction : row / column
function Radio({
  label,
  name,
  content,
  register,
  error,
  defaultChecked,
  setValue,
  visible,
  direction = 'row',
  padding = '.5rem',
  onChange,
  onKeyDown,
  validation = defaultValidation,
  onFocus,
  className = '',
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(ref.current, visible);
  }, [visible]);

  const inputRegister = register && (visible || visible === undefined) ? register(name, validation) : null;

  const rows = content.map((row, key) => (
    <div key={key}>
      <input
        className='input-radio'
        type='radio'
        id={name + key}
        name={name}
        {...inputRegister}
        onChange={e => {
          inputRegister.onChange(e);
          onChange && onChange(e);
        }}
        value={row.value}
      />
      <label htmlFor={name + key} className='radio-container' style={{ '--padding': padding }}>
        <p className='radio-label'>{row.label}</p>
      </label>
    </div>
  ));

  return (
    <div className={`w-100 ${className}`} ref={ref}>
      <div
        className={`field-container radio-field-container ${error ? 'with-error' : ''}`}
        onKeyDown={onKeyDown}
        onFocus={onFocus}>
        <label className='form-label'>{label}</label>
        <span className='input-container'>
          <div className='radio-group' style={{ '--direction': direction }}>
            {rows}
          </div>
          {error && (
            <div className='form-error'>
              <Warning /> <p>{error}</p>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}

export default Radio;
