import React, { useRef, useEffect } from 'react';
import { toggleCollapseElement } from '../../../../utils/Utils.js';
import { selectValidation } from '../../../../utils/formValidation/FormValidation.js';
import Warning from '../../../atoms/icons/general/warning/Warning';

function Select({
  label,
  name,
  register,
  children,
  error,
  visible,
  onChange,
  onKeyDown,
  onFocus,
  className = '',
  shouldStickBottom = true,
  value,
  defaultOption = <option value='_null'>...</option>,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(ref.current, visible);
  }, [visible]);

  const inputRegister = register && (visible || visible === undefined) ? register(name, selectValidation) : null;

  return (
    <div className={`w-100 d-flex ${className} ${shouldStickBottom ? '' : 'mt-0 mb-auto'}`} ref={ref}>
      <div className='w-100'>
        <div className='field-container'>
          <label className='form-label'>{label}</label>
          <span className={`input-container ${error ? 'with-error' : ''}`}>
            <select
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              id={name}
              disabled={visible !== undefined && !visible}
              {...inputRegister}
              onChange={e => {
                inputRegister && inputRegister.onChange(e);
                onChange && onChange(e);
              }}
              value={value}>
              {defaultOption}
              {children}
            </select>
            {error && (
              <div className='form-error'>
                <Warning /> <p>{error}</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Select;
