import React from 'react';
import './TablePdf.css';

// data = {
//   thead: [''],
//   tbody: [
//     {
//       type: 1,
//       cells: [''],
//     },
//   ],
// };

function TablePdf({ data, className = '' }) {
  function getCellClass(type) {
    switch (type) {
      case 2:
        return 'cell-dark-color-background';
      case 3:
        return 'cell-color-background';
      case 4:
        return 'cell-light-color-background';
      default:
        return '';
    }
  }

  return (
    <>
      <div possible-page-break='true' />
      <table className={`table-pdf-container ${className}`}>
        <thead>
          <tr>
            {data.thead.map((label, index) => (
              <th key={index}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.tbody.map((row, index) => (
            <tr key={index} className={getCellClass(row.type) + (row.fullLength ? 'full-length-row' : '')}>
              <th>{row.cells[0]}</th>
              {row.cells.map((cell, index) => index > 0 && <td key={index}>{cell}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
      <div possible-page-break='true' />
    </>
  );
}

export default TablePdf;
