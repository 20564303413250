import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';

import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import { removeSpaces } from '../../../../../utils/Utils';

function LocauxActivite({ id, index }) {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { register, setValue, watch };
  const {
    dependanceLocalCommercial,
    moveToNextStep,
    step,
    setStep,
    submitFormsFdc,
    getStepToGo,
    setGoToStep,
    postValues,
    fillSelectsFromEstimationValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const description_locaux = watch('description_locaux_checkbox');
  const terrain = watch('terrain_checkbox');

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'locaux',
      ['proprietaire', 'reserve', 'stationnement_facile'],
      setValue,
      estimationValues,
    );
    fillInputsFromEstimationValues(
      'locaux',
      ['surface_locaux', 'surface_terrain', 'description_locaux'],
      setValue,
      estimationValues,
    );

    if (estimationValues?.locaux?.surface_terrain) setValue('terrain_checkbox', true);

    if (estimationValues?.locaux?.description_locaux) setValue('description_locaux_checkbox', true);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      locaux: {
        surface_locaux: removeSpaces(values.surface_locaux),
        surface_terrain: values.terrain_checkbox ? removeSpaces(values.surface_terrain) : null,
        proprietaire: { id: values.proprietaire },
        reserve: { id: values.reserve },
        stationnement_facile: { id: values.stationnement_facile },
        description_locaux: values.description_locaux_checkbox ? values.description_locaux : null,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>Locaux d'activité</h2>
        <p className={dependanceLocalCommercial ? 'mt-3' : 'd-none'}>
          Pas besoin de remplir cette étape si vous ne disposez pas de local commercial&nbsp;!
        </p>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            visible={!dependanceLocalCommercial}
            label='surface des locaux (m²)'
            name='surface_locaux'
            error={errors?.surface_locaux && errors.surface_locaux.message}
          />
          <div className='w-100'>
            <Checkbox
              register={register}
              visible={!dependanceLocalCommercial}
              label='Le local possède un terrain'
              name='terrain_checkbox'
            />
          </div>
        </div>
        <div className='row-1000'>
          <div className='w-100' />
          <NumberInput
            useForm={Form}
            name='surface_terrain'
            label='Surface du terrain (m²)'
            visible={terrain}
            error={errors?.surface_terrain && errors.surface_terrain.message}
          />
        </div>
        <div className='row-1000'>
          <Radio
            visible={!dependanceLocalCommercial}
            label='Propriétaire'
            direction='column'
            register={register}
            error={errors?.proprietaire && errors.proprietaire.message}
            name='proprietaire'
            content={[
              {
                label: 'Non',
                value: 1,
              },
              {
                label: 'Oui',
                value: 2,
              },
            ]}
          />
          <Radio
            visible={!dependanceLocalCommercial}
            label='réserve / espace de stockage'
            direction='column'
            register={register}
            error={errors?.reserve && errors.reserve.message}
            name='reserve'
            content={[
              {
                label: 'Sans objet',
                value: 1,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 3,
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            visible={!dependanceLocalCommercial}
            label='stationnement facile'
            direction='column'
            register={register}
            error={errors?.stationnement_facile && errors.stationnement_facile.message}
            name='stationnement_facile'
            content={[
              {
                label: 'Sans objet',
                value: 1,
              },
              {
                label: 'Non',
                value: 2,
              },
              {
                label: 'Oui',
                value: 3,
              },
            ]}
          />
          <div className='w-100' />
        </div>
        <Checkbox
          visible={!dependanceLocalCommercial}
          name='description_locaux_checkbox'
          label='Je souhaite ajouter une description des locaux'
          register={register}
          onFocus={() => {
            if (step == index + 1 && !description_locaux) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && !description_locaux) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <Textarea
          error={errors?.description_locaux && errors.description_locaux.message}
          visible={!dependanceLocalCommercial && description_locaux}
          register={register}
          name='description_locaux'
          label='description des locaux'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default LocauxActivite;
