import './DoubleInput.css';
import React, { useEffect, useRef } from 'react';
import { defaultValidation, numberValidation } from '../../../../utils/formValidation/FormValidation.js';
import '../caseInput/CaseInput.css';
import { formatNumberWithSpaces, removeSpacesFromEvent, toggleCollapseElement } from '../../../../utils/Utils.js';
import Warning from '../../../atoms/icons/general/warning/Warning.js';

// useForm = {register: register, watch: watch, setValue: setValue}
// icon : euro / percent / search
function DoubleInput({
  name,
  name1,
  placeholder1,
  defaultValue,
  validation = numberValidation,
  validation1 = defaultValidation,
  icon = 'euro',
  visible,
  onChange,
  onChange1,
  zeroByDefault = true,
  onFocus,
  disabled,
  disabled1,
  onKeyDown,
  useForm,
  value,
  error,
  error1,
  className = '',
  onBlur1 = e => {},
  onBlur = e => {},
}) {
  const inputRegister =
    useForm.register && (visible || visible === undefined) ? useForm.register(name, validation) : null;
  const containerRef = useRef(null);
  const listener = useForm.watch ? useForm.watch(name) : null;

  const inputRegister1 =
    useForm.register && (visible || visible === undefined) ? useForm.register(name1, validation1) : null;

  useEffect(() => {
    if (listener) useForm.setValue(name, formatNumberWithSpaces(listener));
  }, [listener]);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(containerRef.current, visible);
  }, [visible]);

  function setZero(e) {
    if (e.target.value === '' && useForm.setValue && zeroByDefault) useForm.setValue(name, 0);
  }

  function resetValue(e) {
    if (e.target.value === '0' && useForm.setValue) useForm.setValue(name, '');
  }

  return (
    <>
      <div className={`w-100 d-flex ${className}`} ref={containerRef}>
        <div className={`case-input-container double-input-container ${error || error1 ? 'with-error' : ''}`}>
          <div className='w-100'>
            <input
              maxLength={250}
              className='first-input'
              disabled={(visible !== undefined && !visible) || disabled1}
              id={name1}
              {...inputRegister1}
              placeholder={placeholder1}
              onChange={e => {
                inputRegister && inputRegister.onChange(e);
                onChange1 && onChange1(e);
              }}
              onBlur={onBlur1}
            />
            {error1 && (
              <div className='form-error'>
                <Warning /> <p>{error1}</p>
              </div>
            )}
          </div>
          <span className={'before-' + icon}>
            <input
              disabled={(visible !== undefined && !visible) || disabled}
              id={name}
              value={value !== undefined ? value : undefined}
              {...inputRegister}
              onChange={e => {
                useForm.setValue && useForm.setValue(name, formatNumberWithSpaces(e.target.value));
                inputRegister && inputRegister.onChange(removeSpacesFromEvent(e));
                onChange && onChange(removeSpacesFromEvent(e));
              }}
              onFocus={e => {
                resetValue(e);
                onFocus && onFocus(e);
              }}
              defaultValue={defaultValue}
              onBlur={e => {
                setZero(e);
                onBlur(e);
              }}
              onKeyDown={onKeyDown}
            />
            {error && (
              <div className='form-error'>
                <Warning /> <p>{error}</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export default DoubleInput;
