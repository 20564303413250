import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Select from '../../../../molecules/formComponents/select/Select';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi';
import { getSelectValue } from '../../../../../utils/Utils';
import MultiplesInputs, {
  fillMultipleInputs,
} from '../../../../molecules/formComponents/multiplesInputs/MultiplesInputs';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import Tuto from '../../../../atoms/icons/general/tuto/Tuto';

function NormesAccessibilite({ id, index }) {
  const {
    register,
    setValue,
    unregister,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const Form = { register, setValue, watch };
  const {
    showTooltip,
    typesCommerce,
    moveToNextStep,
    getStepToGo,
    step,
    setStep,
    setGoToStep,
    submitFormsFdc,
    postValues,
    dependanceLocalCommercial,
    fillSelectsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const [dropdownsValues, setDropdownsValues] = useState({});
  const materielWatcher = watch('materiel_checkbox');

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'normes_accessibilite',
      ['accessibilite_pmr', 'installations_electriques', 'systeme_encaissement', 'extraction'],
      setValue,
      estimationValues,
    );
    fillSelectsFromEstimationValues(
      'materiel_agencements',
      ['etat_locaux', 'etat_materiel'],
      setValue,
      estimationValues,
    );

    fillMultipleInputs(estimationValues?.materiel_agencements?.liste_materiel, 'materiel', setValue, 2);
    setValue('materiel_checkbox', !!estimationValues?.materiel_agencements?.liste_materiel.length);
  }, [estimationValues, dropdownsValues]);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  async function formSubmit(values) {
    postValues({
      normes_accessibilite: {
        accessibilite_pmr: { id: getSelectValue(values.accessibilite_pmr) },
        installations_electriques: { id: getSelectValue(values.installations_electriques) },
        systeme_encaissement: { id: getSelectValue(values.systeme_encaissement) },
        extraction: { id: getSelectValue(values.extraction) },
      },
      materiel_agencements: {
        etat_locaux: { id: getSelectValue(values.etat_locaux) },
        etat_materiel: { id: getSelectValue(values.etat_materiel) },
        liste_materiel: values.materiel
          ? values.materiel
              .map(value => {
                const { id, ...values } = value;
                return values;
              })
              .filter(value => value.name)
          : [],
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.accessibilite_pmr = (await fetchDropdownValuesApi('accessibilite_pmr')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.installations_electriques = (await fetchDropdownValuesApi('installations_electriques')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.systeme_encaissement = (await fetchDropdownValuesApi('systeme_encaissement')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.extraction = (await fetchDropdownValuesApi('extraction')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.etat_locaux = (await fetchDropdownValuesApi('etat_locaux')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.etat_materiel = (await fetchDropdownValuesApi('etat_materiel')).data;
    } catch (error) {
      console.error(error);
    }

    setDropdownsValues(values);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      {!dependanceLocalCommercial && (
        <fieldset className='borderless-fieldset'>
          <h2>normes & accessibilité</h2>
          <Select
            label={
              <>
                accessibilité pmr
                <Tuto onClick={() => showTooltip('step_12_tuto_1.webp')} />
              </>
            }
            register={register}
            error={errors?.accessibilite_pmr && errors.accessibilite_pmr.message}
            name='accessibilite_pmr'>
            {dropdownsValues?.accessibilite_pmr?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
          <div className='row-1000 mb-sm'>
            <Select
              label={
                <>
                  conformité des installations électriques
                  <Tuto onClick={() => showTooltip('step_12_tuto_2.webp')} />
                </>
              }
              register={register}
              error={errors?.installations_electriques && errors.installations_electriques.message}
              name='installations_electriques'>
              {dropdownsValues?.installations_electriques?.map(value => (
                <option key={value.id} value={value.id}>
                  {value.description}
                </option>
              ))}
            </Select>
            <Select
              label={
                <>
                  conformité du système d’encaissement
                  <Tuto onClick={() => showTooltip('step_12_tuto_3.webp')} />
                </>
              }
              register={register}
              error={errors?.systeme_encaissement && errors.systeme_encaissement.message}
              name='systeme_encaissement'>
              {dropdownsValues?.systeme_encaissement?.map(value => (
                <option key={value.id} value={value.id}>
                  {value.description}
                </option>
              ))}
            </Select>
          </div>
          <Select
            visible={typesCommerce.includes(3)}
            label='extraction'
            register={register}
            error={errors?.extraction && errors.extraction.message}
            name='extraction'>
            {dropdownsValues?.extraction?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </fieldset>
      )}

      <fieldset className='borderless-fieldset'>
        <h2>matériel & agencements</h2>
        <div className='row-1000 mb-sm'>
          <Select
            visible={!dependanceLocalCommercial}
            className={!dependanceLocalCommercial ? '' : 'w-0'}
            label={
              <>
                Etat des locaux
                <Tuto onClick={() => showTooltip('step_12_tuto_4.webp')} />
              </>
            }
            register={register}
            error={errors?.etat_locaux && errors.etat_locaux.message}
            name='etat_locaux'>
            {dropdownsValues?.etat_locaux?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
          <Select
            label={
              <>
                Etat du matériel
                <Tuto onClick={() => showTooltip('step_12_tuto_5.webp')} />
              </>
            }
            register={register}
            error={errors?.etat_materiel && errors.etat_materiel.message}
            name='etat_materiel'>
            {dropdownsValues?.etat_materiel?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <Checkbox
          register={register}
          name='materiel_checkbox'
          label='Je souhaite renseigner le détail du matériel'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && !e.target.checked) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <MultiplesInputs
          visible={materielWatcher}
          label='Détail du matériel'
          inputType={2}
          Form={{ ...Form, setError, clearErrors, errors, getValues, unregister, trigger }}
          name='materiel'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default NormesAccessibilite;
