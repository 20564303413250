import React, { useContext, useEffect } from 'react';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import {
  formatNumberWithSpaces,
  formatObjectForPosting,
  getTotalFromValues,
  removeSpaces,
} from '../../../../../utils/Utils';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import Input from '../../../../molecules/formComponents/input/Input';
import Tuto from '../../../../atoms/icons/general/tuto/Tuto';

function ComptesResultat({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    showTooltip,
    compteResultatInputs,
    moveToNextStep,
    getStepToGo,
    typesCommerce,
    setGoToStep,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const recapWatcher = watch([
    'ventes_marchandise',
    'achats_marchandise',
    'marge_commerciale',
    'taux_marge_commerciale',
    'production_prestations_vendues',
    'achats_matiere_premiere',
    'marge_sur_productions',
    'taux_marge_productions',
    'marge_brute_globale',
    'taux_marge_brute_globale',
    'ventes_marchandise_hebergement',
    'ventes_marchandise_restauration',
  ]);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'compte_resultat',
      [
        'ventes_marchandise',
        'ventes_marchandise_hebergement',
        'ventes_marchandise_restauration',
        'achats_marchandise',
        'marge_commerciale',
        'total_commissions_nettes',
        'production_prestations_vendues',
        'achats_matiere_premiere',
        'marge_sur_productions',
        'ca_global',
        'marge_brute_globale',
        'achats_charges_externes',
        'valeur_ajoutee',
        'subvention_exploitation',
        'impots_societe',
        'impots_taxes',
        'salaires_annuels',
        'charges_sociales_annuelles',
        'excedent_brut_exploitation',
        'reprises_sur_provisions_transfert_charges',
        'autres_produits',
        'dotations_amortissements_provisions',
        'autres_charges',
        'resultat_exploitation',
        'produits_financiers',
        'charges_financieres',
        'resultat_financier',
        'resultat_courant',
        'produits_exceptionnels',
        'charges_exceptionnelles',
        'resultat_exceptionnel',
        'resultat_exercice',
        'note_compte_resultat',
        'ca_transaction',
        'ca_location',
        'ca_gestion_locative',
        'ca_syndic',
      ],
      setValue,
      estimationValues,
    );

    setValue('note_detail_marge', estimationValues?.detail_marge?.note_detail_marge);

    calcTauxMargeCommerciale();
    calcTauxMargeProductions();
    calcMargeBruteGlobale();
    calcTauxMargeBruteGlobale();
  }, [estimationValues]);

  async function formSubmit(values) {
    const note = values.note_compte_resultat;
    delete values.note_compte_resultat;
    const note1 = values.note_detail_marge;
    delete values.note_detail_marge;

    const formattedValues = formatObjectForPosting(values);
    const detailMargeValues = {
      taux_marge_commerciale: formattedValues.taux_marge_commerciale,
      taux_marge_productions: formattedValues.taux_marge_productions,
      marge_brute_globale: formattedValues.marge_brute_globale,
      taux_marge_brute_globale: formattedValues.taux_marge_brute_globale,
    };

    delete formattedValues.taux_marge_commerciale;
    delete formattedValues.taux_marge_productions;
    delete formattedValues.taux_marge_brute_globale;

    const tmp = {
      compte_resultat: formattedValues,
      detail_marge: detailMargeValues,
    };

    if (!secondaryParcours) tmp.compte_resultat.note_compte_resultat = note;
    if (!secondaryParcours) tmp.detail_marge.note_detail_marge = note1;

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }
  // TODO REFAIRE LES CALCULS AVEC LE TABLEAU COMPTERESULTATINPUTS AU LIEU DE TOUS LES CA COMME UN SAC A MERDE

  //calcul auto des inputs
  function calcMargeCommerciale() {
    const tmp = getValues([
      'ventes_marchandise',
      'ventes_marchandise_hebergement',
      'ventes_marchandise_restauration',
      'ca_transaction',
      'ca_location',
      'ca_gestion_locative',
      'ca_syndic',
    ]);

    setValue(
      'marge_commerciale',
      removeSpaces(getTotalFromValues(tmp), true) - removeSpaces(getValues('achats_marchandise'), true),
    );

    calcMargeBruteGlobale();
    calcValeurAjoutee();
    calcTauxMargeCommerciale();
    calcMargeBruteGlobale();
  }

  function calcMargeSurProductions() {
    const tmp = getValues(['production_prestations_vendues', 'total_commissions_nettes', 'achats_matiere_premiere']);

    setValue(
      'marge_sur_productions',
      removeSpaces(tmp[0], true) + removeSpaces(tmp[1], true) - removeSpaces(tmp[2], true),
    );

    calcMargeBruteGlobale();
    calcTauxMargeProductions();
    calcMargeBruteGlobale();
  }

  function calcCaGlobal() {
    const tmp = getValues([
      'ventes_marchandise',
      'ventes_marchandise_hebergement',
      'ventes_marchandise_restauration',
      'ca_transaction',
      'ca_location',
      'ca_gestion_locative',
      'ca_syndic',
      'production_prestations_vendues',
      'total_commissions_nettes',
    ]);

    setValue('ca_global', getTotalFromValues(tmp));
  }

  function calcMargeBruteGlobale() {
    const tmp = getValues(['marge_sur_productions', 'marge_commerciale']);

    setValue('marge_brute_globale', getTotalFromValues(tmp));
  }

  function calcValeurAjoutee() {
    const tmp = getValues(['marge_brute_globale', 'achats_charges_externes']);

    setValue('valeur_ajoutee', removeSpaces(tmp[0], true) - removeSpaces(tmp[1], true));

    calcEbe();
  }

  function calcEbe() {
    const tmp = getValues([
      'valeur_ajoutee',
      'subvention_exploitation',
      'impots_taxes',
      'salaires_annuels',
      'charges_sociales_annuelles',
    ]);

    setValue(
      'excedent_brut_exploitation',
      removeSpaces(tmp[0], true) +
        removeSpaces(tmp[1], true) -
        removeSpaces(tmp[2], true) -
        removeSpaces(tmp[3], true) -
        removeSpaces(tmp[4], true),
    );

    calcResultatExploitation();
  }

  function calcResultatExploitation() {
    const tmp1 = getValues([
      'excedent_brut_exploitation',
      'reprises_sur_provisions_transfert_charges',
      'autres_produits',
    ]);
    const tmp2 = getValues(['dotations_amortissements_provisions', 'autres_charges']);

    setValue(
      'resultat_exploitation',
      removeSpaces(getTotalFromValues(tmp1), true) - removeSpaces(getTotalFromValues(tmp2), true),
    );

    calcResultatCourant();
  }

  function calcResultatFinancier() {
    const tmp = getValues(['produits_financiers', 'charges_financieres']);

    setValue('resultat_financier', removeSpaces(tmp[0], true) - removeSpaces(tmp[1], true));

    calcResultatCourant();
  }

  function calcResultatCourant() {
    const tmp = getValues(['resultat_exploitation', 'resultat_financier']);

    setValue('resultat_courant', getTotalFromValues(tmp));

    calcResultatExercice();
  }

  function calcResultatExceptionnel() {
    const tmp = getValues(['produits_exceptionnels', 'charges_exceptionnelles']);

    setValue('resultat_exceptionnel', removeSpaces(tmp[0], true) - removeSpaces(tmp[1], true));

    calcResultatExercice();
  }

  function calcResultatExercice() {
    const tmp = getValues(['resultat_courant', 'resultat_exceptionnel', 'impots_societe']);

    setValue('resultat_exercice', removeSpaces(tmp[0], true) + removeSpaces(tmp[1], true) - removeSpaces(tmp[2], true));
  }

  function calcTauxMargeCommerciale() {
    let values = getValues([
      'marge_commerciale',
      'ventes_marchandise',
      'ventes_marchandise_hebergement',
      'ventes_marchandise_restauration',
      'ca_transaction',
      'ca_location',
      'ca_gestion_locative',
      'ca_syndic',
    ]);

    const margeCommerciale = removeSpaces(values.shift(), true);
    const result = (margeCommerciale / removeSpaces(getTotalFromValues(values))) * 100;

    setValue('taux_marge_commerciale', result ? result.toFixed(2) : 0);
  }

  function calcTauxMargeProductions() {
    let values = getValues(['marge_sur_productions', 'production_prestations_vendues', 'total_commissions_nettes']);
    values = values.map(value => removeSpaces(value, true));
    const result = (values[0] / (values[1] + values[2])) * 100;

    setValue('taux_marge_productions', result ? result.toFixed(2) : 0);
  }

  function calcTauxMargeBruteGlobale() {
    let values = getValues([
      'marge_brute_globale',
      'ventes_marchandise',
      'ventes_marchandise_hebergement',
      'ventes_marchandise_restauration',
      'total_commissions_nettes',
      'production_prestations_vendues',
    ]);

    const margeBruteGlobale = removeSpaces(values.shift(), true);
    const result = (margeBruteGlobale / removeSpaces(getTotalFromValues(values))) * 100;

    setValue('taux_marge_brute_globale', result ? result.toFixed(2) : 0);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>Compte de résultat</h2>
        <div className='row-1000 gap-sm'>
          <div className='w-100'>
            {compteResultatInputs.rest?.map(input => (
              <NumberInput
                key={input.id}
                useForm={Form}
                name={input.input_name}
                icon='euro'
                error={errors?.[input.input_name] && errors[input.input_name].message}
                label={
                  <>
                    {input.label}
                    {input.tuto && <Tuto onClick={() => showTooltip(input.tuto)} />}
                  </>
                }
                onChange={() => {
                  calcMargeCommerciale();
                  calcCaGlobal();
                  calcTauxMargeCommerciale();
                  calcTauxMargeBruteGlobale();
                }}
              />
            ))}
            <NumberInput
              useForm={Form}
              label={
                <>
                  Achats de marchandises
                  <Tuto onClick={() => showTooltip('step_6_tuto_2.webp')} />
                </>
              }
              name='achats_marchandise'
              error={errors?.achats_marchandise && errors.achats_marchandise.message}
              icon='euro'
              onChange={() => {
                calcMargeCommerciale();
                calcCaGlobal();
                calcTauxMargeCommerciale();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Marge commerciale'
              name='marge_commerciale'
              error={errors?.marge_commerciale && errors.marge_commerciale.message}
              icon='euro'
            />
            {compteResultatInputs.production_prestations_vendues?.input_name && (
              <NumberInput
                useForm={Form}
                label={
                  <>
                    {compteResultatInputs.production_prestations_vendues?.label}
                    <Tuto onClick={() => showTooltip(compteResultatInputs.production_prestations_vendues?.tuto)} />
                  </>
                }
                name={compteResultatInputs.production_prestations_vendues?.input_name}
                error={
                  errors?.compteResultatInputs?.production_prestations_vendues?.input_name &&
                  errors?.compteResultatInputs?.production_prestations_vendues?.input_name.message
                }
                icon='euro'
                onChange={() => {
                  calcMargeSurProductions();
                  calcCaGlobal();
                  calcTauxMargeProductions();
                  calcTauxMargeBruteGlobale();
                }}
              />
            )}

            <NumberInput
              useForm={Form}
              visible={typesCommerce.includes(2)}
              label={
                <>
                  Total des commissions nettes
                  <Tuto
                    onClick={() =>
                      showTooltip(
                        typesCommerce.includes(1)
                          ? 'step_6_tuto_commissions_nettes_auberge_hotel_plage.webp'
                          : 'step_6_tuto_commissions_nettes_tabac.webp',
                      )
                    }
                  />
                </>
              }
              name='total_commissions_nettes'
              error={errors?.total_commissions_nettes && errors.total_commissions_nettes.message}
              icon='euro'
              onChange={() => {
                calcMargeSurProductions();
                calcCaGlobal();
                calcTauxMargeProductions();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Achats de matières 1ères
                  <Tuto onClick={() => showTooltip('step_6_tuto_4.webp')} />
                </>
              }
              name='achats_matiere_premiere'
              error={errors?.achats_matiere_premiere && errors.achats_matiere_premiere.message}
              icon='euro'
              onChange={calcMargeSurProductions}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Marge sur production'
              name='marge_sur_productions'
              error={errors?.marge_sur_productions && errors.marge_sur_productions.message}
              icon='euro'
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Chiffre d'affaires global"
              name='ca_global'
              error={errors?.ca_global && errors.ca_global.message}
              icon='euro'
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='marge brute globale'
              name='marge_brute_globale'
              error={errors?.marge_brute_globale && errors.marge_brute_globale.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Autres achats & charges externes
                  <Tuto onClick={() => showTooltip('step_6_tuto_5.webp')} />
                </>
              }
              name='achats_charges_externes'
              error={errors?.achats_charges_externes && errors.achats_charges_externes.message}
              icon='euro'
              onChange={calcValeurAjoutee}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Valeur ajoutée'
              name='valeur_ajoutee'
              error={errors?.valeur_ajoutee && errors.valeur_ajoutee.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Subventions d'exploitation
                  <Tuto onClick={() => showTooltip('step_6_tuto_6.webp')} />
                </>
              }
              name='subvention_exploitation'
              error={errors?.subvention_exploitation && errors.subvention_exploitationmessage}
              icon='euro'
              onChange={calcEbe}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Impôts & taxes
                  <Tuto onClick={() => showTooltip('step_6_tuto_7.webp')} />
                </>
              }
              name='impots_taxes'
              error={errors?.impots_taxes && errors.impots_taxes.message}
              icon='euro'
              onChange={calcEbe}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Total des rémunérations annuelles (salariés + dirigeants)
                  <Tuto onClick={() => showTooltip('step_6_tuto_8.webp')} />
                </>
              }
              name='salaires_annuels'
              error={errors?.salaires_annuels && errors.salaires_annuels.message}
              icon='euro'
              onChange={calcEbe}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Total des charges sociales annuelles (salariés + dirigeants)
                  <Tuto onClick={() => showTooltip('step_6_tuto_8_1.webp')} />
                </>
              }
              name='charges_sociales_annuelles'
              error={errors?.charges_sociales_annuelles && errors.charges_sociales_annuelles.message}
              icon='euro'
              onChange={calcEbe}
            />
          </div>
          <div
            className='parcours-separator'
            style={{ minHeight: '100%', width: '5px', backgroundColor: 'var(--secondary-color)', borderRadius: '3px' }}
          />
          <div className='w-100'>
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Excédent brut d'exploitation"
              name='excedent_brut_exploitation'
              error={errors?.excedent_brut_exploitation && errors.excedent_brut_exploitation.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Reprises s/ amort, dépréciat° et provis°. transferts de charges
                  <Tuto onClick={() => showTooltip('step_6_tuto_9.webp')} />
                </>
              }
              name='reprises_sur_provisions_transfert_charges'
              error={
                errors?.reprises_sur_provisions_transfert_charges &&
                errors.reprises_sur_provisions_transfert_charges.message
              }
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Autres produits
                  <Tuto onClick={() => showTooltip('step_6_tuto_10.webp')} />
                </>
              }
              name='autres_produits'
              error={errors?.autres_produits && errors.autres_produits.message}
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Dotations aux amortissements et provisions
                  <Tuto onClick={() => showTooltip('step_6_tuto_11.webp')} />
                </>
              }
              name='dotations_amortissements_provisions'
              error={errors?.dotations_amortissements_provisions && errors.dotations_amortissements_provisions.message}
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Autres charges
                  <Tuto onClick={() => showTooltip('step_6_tuto_12.webp')} />
                </>
              }
              name='autres_charges'
              error={errors?.autres_charges && errors.autres_charges.message}
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Résultat d'exploitation"
              name='resultat_exploitation'
              error={errors?.resultat_exploitation && errors.resultat_exploitation.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Produits financiers
                  <Tuto onClick={() => showTooltip('step_6_tuto_13.webp')} />
                </>
              }
              name='produits_financiers'
              error={errors?.produits_financiers && errors.produits_financiers.message}
              icon='euro'
              onChange={calcResultatFinancier}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Charges financières
                  <Tuto onClick={() => showTooltip('step_6_tuto_14.webp')} />
                </>
              }
              name='charges_financieres'
              error={errors?.charges_financieres && errors.charges_financieres.message}
              icon='euro'
              onChange={calcResultatFinancier}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Résultat financier'
              name='resultat_financier'
              error={errors?.resultat_financier && errors.resultat_financier.message}
              icon='euro'
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Résultat courant'
              name='resultat_courant'
              error={errors?.resultat_courant && errors.resultat_courant.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Produits exceptionnels
                  <Tuto onClick={() => showTooltip('step_6_tuto_15.webp')} />
                </>
              }
              name='produits_exceptionnels'
              error={errors?.produits_exceptionnels && errors.produits_exceptionnels.message}
              icon='euro'
              onChange={calcResultatExceptionnel}
            />
            <NumberInput
              useForm={Form}
              label={
                <>
                  Charges exceptionnelles
                  <Tuto onClick={() => showTooltip('step_6_tuto_16.webp')} />
                </>
              }
              name='charges_exceptionnelles'
              error={errors?.charges_exceptionnelles && errors.charges_exceptionnelles.message}
              icon='euro'
              onChange={calcResultatExceptionnel}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Résultat exceptionnel'
              name='resultat_exceptionnel'
              error={errors?.resultat_exceptionnel && errors.resultat_exceptionnel.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label={<>Impôt sur les sociétés</>}
              name='impots_societe'
              error={errors?.impots_societe && errors.impots_societe.message}
              icon='euro'
              onChange={calcResultatExercice}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Résultat de l'exercice"
              name='resultat_exercice'
              error={errors?.resultat_exercice && errors.resultat_exercice.message}
              icon='euro'
            />
          </div>
        </div>
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_compte_resultat'
            label='notes et remarques'
            className='mt-md'
            error={errors?.note_compte_resultat && errors.note_compte_resultat.message}
          />
        )}
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='mb-3'>Détail de l'activité et de la marge</h2>
        <Input
          validation={{}}
          className='d-none'
          type='hidden'
          name='taux_marge_commerciale'
          disabled
          register={register}
        />
        <Input
          validation={{}}
          className='d-none'
          type='hidden'
          name='taux_marge_productions'
          disabled
          register={register}
        />
        <Input
          validation={{}}
          className='d-none'
          type='hidden'
          name='taux_marge_brute_globale'
          disabled
          register={register}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
        />
        <div className='recap-ebe'>
          <div className='recap-row'>
            <p>Ventes de marchandises</p>
            <p>{getTotalFromValues([recapWatcher[0], recapWatcher[10], recapWatcher[11]])}€</p>
          </div>
          <div className='recap-row'>
            <p>Achats de marchandises</p>
            <p>{formatNumberWithSpaces(recapWatcher[1], 1)}€</p>
          </div>
          <div className='recap-row'>
            <p>Marge commerciale</p>
            <p>{formatNumberWithSpaces(recapWatcher[2], 1)}€</p>
          </div>
          <div className='recap-row'>
            <p>Taux de marge commerciale</p>
            <p>{formatNumberWithSpaces(recapWatcher[3], 1)}%</p>
          </div>
          <div className='recap-row'>
            <p>Production / Prestations vendues</p>
            <p>{formatNumberWithSpaces(recapWatcher[4], 1)}€</p>
          </div>
          <div className='recap-row'>
            <p>Achats de matières</p>
            <p>{formatNumberWithSpaces(recapWatcher[5], 1)}€</p>
          </div>
          <div className='recap-row'>
            <p>Marge sur productions</p>
            <p>{formatNumberWithSpaces(recapWatcher[6], 1)}€</p>
          </div>
          <div className='recap-row'>
            <p>Taux de marge sur productions</p>
            <p>{formatNumberWithSpaces(recapWatcher[7], 1)}%</p>
          </div>
          <div className='recap-row'>
            <p>Marge brute globale</p>
            <p>{formatNumberWithSpaces(recapWatcher[8], 1)}€</p>
          </div>
          <div className='recap-row'>
            <p>Taux de marge brute globale</p>
            <p>{formatNumberWithSpaces(recapWatcher[9], 1)}%</p>
          </div>
        </div>
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_detail_marge'
            label='notes et remarques'
            className='mt-md'
            error={errors?.note_detail_marge && errors.note_detail_marge.message}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
    </form>
  );
}

export default ComptesResultat;
